import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  /**
   * Temporary solution
   */
  private _currentLanguage = 'en';

  /**
   * Emits when lang changed
   */
  private _langChange$: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor() {
  }

  /**
   * Access to current language from outside
   */
  get current(): string {
    return this._currentLanguage;
  }

  /**
   * Access to _langChange$ from outside
   */
  get langChange$(): ReplaySubject<string> {
    return this._langChange$;
  }

  /**
   * Change website lang to provided
   *
   * @param lang
   */
  changeLang(lang: string) {
    this._currentLanguage = lang;
    this._langChange$.next(this._currentLanguage);
  }
}
