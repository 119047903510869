import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { forkJoin, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { first, tap } from 'rxjs/operators';
import { TranslationService } from '../translation/translation.service';

@Injectable({
  providedIn: 'root'
})
export class DataPreloaderService implements Resolve<any> {

  /**
   * List of observables that need to be preloaded before app start
   */
  private _dataToPreload: Array<any> = [
    this._env.env$.pipe(first()),
    this._translate.getUserTranslates$().pipe(first())
  ];

  /**
   * Is data loaded
   */
  private _loaded: boolean;

  constructor(
    private _env: EnvironmentService,
    private _translate: TranslationService
  ) { }

  /**
   * Access to _loaded
   */
  get loaded(): boolean {
    return this._loaded;
  }

  /**
   * For main route resolving
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return forkJoin(this._dataToPreload).pipe(
      tap(() => {
        this._loaded = true;
      })
    );
  }
}
