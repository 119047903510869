import { Injectable } from '@angular/core';
import { CmsApiService } from './api/cms-api.service';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  /**
   * Environment observable
   */
  private _environment$: Observable<any> = this._api.serviceEnvironment().pipe(
    catchError(error => of({})),
    shareReplay(1),
    tap(response => this._environment = response.data)
  );

  /**
   * Environment object
   */
  private _environment;

  constructor(
    private _api: CmsApiService
  ) { }

  /**
   * Returns environment ReplaySubject
   */
  get env$(): Observable<any> {
    return this._environment$;
  }

  /**
   * Returns environment object
   */
  get env(): any {
    return this._environment;
  }
}
