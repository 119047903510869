import { BrowserModule } from '@angular/platform-browser';
import {APP_ID, Inject, Injector, NgModule, PLATFORM_ID} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ServiceInjector} from './shared/helpers/service-injector';
import {isPlatformBrowser} from '@angular/common';
import {LazyLoaderService} from './shared/services/lazy-loader.service';
import {CookieService} from 'ngx-cookie-service';
import {ContentUpdaterService} from './shared/services/content-updater/content-updater.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    DeviceDetectorModule.forRoot(),
  ],
  providers: [
    ContentUpdaterService,
    CookieService,
    LazyLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(APP_ID) private appId: string,
    private _injector: Injector
  ) {
    ServiceInjector.injector = _injector;
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
