import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { DICTIONARY } from './translation.data';
import { catchError, tap } from 'rxjs/operators';
import { CmsApiService } from '../services/api/cms-api.service';
import { LanguageService } from '../services/language/language.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  /**
   * Available languages
   */
  private _dictionary: any = DICTIONARY;

  constructor(
    private _cmsApi: CmsApiService,
    private _language: LanguageService
  ) {
  }

  /**
   * Translate text
   *
   * Using: translate('Hello, #name#', {name: 'Alex'});
   * Output: 'Hei Alex'; // (fi lang)
   *
   * @param key
   * @param params
   */
  public translate(key, params = null): string {
    let translated = '';
    if (isNullOrUndefined(this._dictionary[this._language.current]) || isNullOrUndefined(this._dictionary[this._language.current][key])) {
      translated = key;
    } else {
      translated = this._dictionary[this._language.current][key];
    }
    if (params) {
      const keyParams = [];
      const regex = /\#([^^]*?)\#/ig;
      let match;
      while (match = regex.exec(key)) {
        keyParams.push(match[1]);
      }
      keyParams.forEach((item) => {
        translated = translated.replace(`#${item}#`, params[item]);
      });
    }
    return translated;
  }

  /**
   * Load user translates
   */
  public getUserTranslates$() {
    return this._cmsApi.translateList().pipe(
      catchError(error => of({data: []})),
      tap((res: {data: any[]}) => {
        this._resolveLangFormat(res);
      }),
    );
  }

  /**
   * Resolve lang format
   * @param res
   */
  private _resolveLangFormat(res: any) {
    const keyValuePairs = {};
    (res.data || []).forEach((item) => {
      if (!isNullOrUndefined(item.key) && !isNullOrUndefined(item.value)) {
        keyValuePairs[item.key] = item.value;
      }
    });
    DICTIONARY[this._language.current] = keyValuePairs;
  }
}
