import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, share, tap } from 'rxjs/operators';
import { ServiceInjector } from '../../../helpers/service-injector';
import { CacheService } from '../../cache/cache.service';
import { Router, UrlSerializer } from '@angular/router';
import { ApiProviderInterface } from '../interfaces/api-provider-interface';


export class ApiProvider implements ApiProviderInterface {

  /**
   * Inject global HttpClient
   */
  private _http: HttpClient = ServiceInjector.injector.get(HttpClient);

  /**
   * Inject global CacheService
   */
  private _cache: CacheService = ServiceInjector.injector.get(CacheService);

  /**
   * Inject global Router
   */
  private _router: Router = ServiceInjector.injector.get(Router);

  /**
   * Inject global UrlSerializer
   */
  private _urlSerializer: UrlSerializer = ServiceInjector.injector.get(UrlSerializer);

  constructor(
    /**
     * Default options for all requests sent from current instance
     */
    private _defaultOptions: object,
    /**
     * Api host
     */
    private _host?: string,
    /**
     * Uses when url part needs to be resolved for every request
     *
     * For example language in requests to CMS: https://someapi.com/${_urlResolutionFn result}/banners/list
     */
    private _urlResolutionFn: () => string = () => ''
  ) {
  }

  /**
   * DELETE request base implementation
   *
   * @param url
   * @param data
   * @param options
   */
  delete(url: string, data?: object, options: object = {}): Observable<any> {
    const opts = {
      ...options,
      body: data,
    };

    return this.request('DELETE', url, opts);
  }

  /**
   * GET request base implementation
   *
   * @param url
   * @param queryParams
   * @param options
   */
  get(url: string, queryParams?: object, options: object = {}): Observable<any> {
    const opts = {
      ...options,
      params: queryParams,
    };

    return this.request('GET', url, opts);
  }

  /**
   * GET request implementation with cache
   *
   * @param url
   * @param queryParams
   * @param options
   */
  getCached(url: string, queryParams?: object, options?: object): Observable<any> {
    const opts = {
      ...options,
      params: queryParams,
    };

    const cacheKey = this._resolveCacheKey(this._host + this._urlResolutionFn() + url, opts);

    if (this._cache.has(cacheKey)) {
      return this._cache.get(cacheKey);
    } else {
      return this.request('GET', url, opts).pipe(
        tap(res => this._cache.set(cacheKey, res))
      );
    }
  }

  /**
   * PATCH request base implementation
   *
   * @param url
   * @param data
   * @param options
   */
  patch(url: string, data?: object, options: object = {}): Observable<any> {
    const opts = {
      ...options,
      body: data,
    };

    return this.request('PATCH', url, opts);
  }

  /**
   * POST request base implementation
   *
   * @param url
   * @param data
   * @param options
   */
  post(url: string, data?: object, options: object = {}): Observable<any> {
    const opts = {
      ...options,
      body: data,
    };

    return this.request('POST', url, opts);
  }

  /**
   * PUT request base implementation
   *
   * @param url
   * @param data
   * @param options
   */
  put(url: string, data?: object, options: object = {}): Observable<any> {
    const opts = {
      ...options,
      body: data,
    };

    return this.request('PUT', url, opts);
  }

  /**
   *  Universal request base implementation
   *
   * @param method
   * @param url
   * @param options
   */
  request(method: string, url: string, options: object): Observable<any> {

    const opts = {
      ...this._defaultOptions,
      ...options
    };

    return this._http.request(method, this._host + this._urlResolutionFn() + url, opts).pipe(
      share(),
      catchError(error => {
        this.onError(error);

        return throwError(error);
      })
    );
  }

  /**
   * Base error handling implementation
   *
   * @param error
   */
  onError(error: HttpErrorResponse): void {
  }


  /**
   * Create cache key
   *
   * @param url
   * @param options
   * @private
   */
  private _resolveCacheKey(url, options) {
    const urlTree = this._router.createUrlTree([url], {
      queryParams: options.params
    });

    return this._urlSerializer.serialize(urlTree);
  }
}
