import { Compiler, Injectable, Injector, NgModuleFactory, ViewContainerRef } from '@angular/core';

/**
 * Lazy modules should be listed here
 */
export const LAZY_MODULES = {

};

@Injectable({
  providedIn: 'root'
})
export class LazyLoaderService {

  constructor(
    private injector: Injector,
    private compiler: Compiler,
  ) {
  }

  async load(name: string, container: ViewContainerRef) {
    const tempModule = await LAZY_MODULES[name]();

    let moduleFactory;

    if (tempModule instanceof NgModuleFactory) {
      moduleFactory = tempModule;
    } else {
      moduleFactory = await this.compiler.compileModuleAsync(tempModule);
    }

    const compFactory = moduleFactory.create(this.injector)
      .componentFactoryResolver.resolveComponentFactory((moduleFactory.moduleType as any).entry);

    container.createComponent(compFactory);
  }
}
