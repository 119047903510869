import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { WindowService } from './window.service';
import { fromEvent, ReplaySubject, Subject } from 'rxjs';
import { PlatformService } from './platform.service';
import { Inject, Injectable } from '@angular/core';
import { NgZone } from '@angular/core';
import { map, tap, filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

export interface WindowSize {
  width: number | any;
  height: number | any;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsService {

  public resize$: ReplaySubject<any> = new ReplaySubject(1);
  public orientationchange$: ReplaySubject<any> = new ReplaySubject(1);
  public scroll$: ReplaySubject<any> = new ReplaySubject(1);
  public beforeinstallprompt$: ReplaySubject<any> = new ReplaySubject(1);
  public routerNavigationStart$: ReplaySubject<any> = new ReplaySubject(1);
  public routerNavigationEnd$: ReplaySubject<any> = new ReplaySubject(1);
  public globalRouterEvent$: ReplaySubject<any> = new ReplaySubject(1);
  public fullscreenChangeEvent$: ReplaySubject<any> = new ReplaySubject(1);
  public handleEnterClick$: Subject<any> = new Subject<any>();
  public isHomePage$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public isLeaveBrowserTab$: ReplaySubject<any> = new ReplaySubject(1);

  private _isLeaveBrowserTab = null;


  constructor(
    private _platform: PlatformService,
    private _zone: NgZone,
    private _window: WindowService,
    private _router: Router,
    @Inject(DOCUMENT) private _document: Document
  ) {
  }

  public initGlobalEvent() {
    this._runRouterNavigationListener();
    if (this._platform.isBrowser) {
      this._zone.run(() => {
        this._runScrollListener();
        this._runResizeListener();
        this._runOrientationChangeListener();
        this._runBeforeInstallPromptListener();
        this._runFullscreenChangeEventListener();
        this._runEnterClick();
        this._handlerLeaveBrowserTab();
      });
    }
  }

  /**
   * Run global scroll listener
   */
  private _runScrollListener() {
    fromEvent(this._window.nativeWindow, 'scroll', { passive: true, capture: false })
      .subscribe((e) => this.scroll$.next(e));
  }

  /**
   * Run global resize listener
   */
  private _runResizeListener() {
    this._document.documentElement.style.setProperty('--app-height', `${this._window.nativeWindow.innerHeight}px`);
    this.resize$.next(<WindowSize>{
      width: this._window.nativeWindow.innerWidth,
      height: this._window.nativeWindow.innerHeight
    })
    fromEvent(this._window.nativeWindow, 'resize', { passive: true }).pipe(
      map(event => <WindowSize>{
        width: this._window.nativeWindow.innerWidth,
        height: this._window.nativeWindow.innerHeight
      }),
      tap(e => {
        this._document.documentElement.style.setProperty('--app-height', `${e.height}px`);
      })
    ).subscribe((e) => this.resize$.next(e));
  }

  /**
   * Run global orientation change listener
   */
  private _runOrientationChangeListener() {
    fromEvent(this._window.nativeWindow, 'orientationchange', { passive: true })
      .subscribe((e) => this.orientationchange$.next(e));
  }

  /**
   * Run global before install prompt listener
   */
  private _runBeforeInstallPromptListener() {
    fromEvent(this._window.nativeWindow, 'beforeinstallprompt')
      .subscribe((e) => this.beforeinstallprompt$.next(e));
  }

  /**
   * Run global router event listener
   */
  private _runRouterNavigationListener() {
    this._router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.routerNavigationStart$.next(e);
      }
      if (e instanceof NavigationEnd) {
        this.routerNavigationEnd$.next(e);
        if (e.url.split('/').length <= 2) {
          this.isHomePage$.next(true);
        } else {
          this.isHomePage$.next(false);
        }
      }
      this.globalRouterEvent$.next(e);
    });
  }

  /**
   * Run global fullscreenchangeEvent listener
   */
  private _runFullscreenChangeEventListener() {
    fromEvent(this._window.nativeWindow, 'fullscreenchange')
      .subscribe((e) => this.fullscreenChangeEvent$.next(e));
  }

  /**
   * Run global click by Enter key
   * @private
   */
  private _runEnterClick() {
    fromEvent(this._document.documentElement, 'keyup')
      .pipe(
        tap((e: any) => e.stopImmediatePropagation()),
        filter((e: any) => e.keyCode === 13),
        tap((e) => {
          this.handleEnterClick$.next(e);
        }),
      ).subscribe();
  }

  private _handlerLeaveBrowserTab() {
    if (this._platform.isBrowser) {
      fromEvent(this._window.nativeWindow, 'blur').subscribe((e: any) => {
        if (e.eventPhase && !this._isLeaveBrowserTab) {
          this._isLeaveBrowserTab = true;
          this.isLeaveBrowserTab$.next(true);
        }
      });
      fromEvent(this._window.nativeWindow, 'focus').subscribe((e: any) => {
        if (e.eventPhase && this._isLeaveBrowserTab) {
          this._isLeaveBrowserTab = false;
          this.isLeaveBrowserTab$.next(false);
        }
      });
    }
  }

}
