import { Injectable } from '@angular/core';
import { ApiProvider } from './helpers/api-provider';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../language/language.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CmsApiService {

  /**
   * For API communication with lang segment
   */
  private _api: ApiProvider = new ApiProvider({
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Basic Y2FzaW5vOmNhc2lubzIwMjA=',
    }),
    withCredentials: true
  },
    environment.api_host,
    () => '/' + this._lang.current
  );

  /**
   * For API communication without lang segment
   */
  private _noLangApi: ApiProvider = new ApiProvider({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Basic Y2FzaW5vOmNhc2lubzIwMjA=',
      }),
      withCredentials: true
    },
    environment.api_host
  );

  constructor(
    private _lang: LanguageService,
  ) { }

  /**
   * /banner/list
   *
   * @param params
   */
  bannerList(params: object = {}): Observable<any> {
    return this._api.getCached('/banner/list', params);
  }

  /**
   * /banner/item
   *
   * @param params
   */
  bannerItem(params: object = {}): Observable<any> {
    return this._api.getCached('/banner/item', params);
  }

  /**
   * /page/item
   *
   * @param params
   */
  pageItem(params: object = {}): Observable<any> {
    return this._api.getCached('/page/item', params);
  }

  /**
   * /payment/list
   *
   * @param params
   */
  paymentList(params: object = {}): Observable<any> {
    return this._api.getCached('/payment/list', params);
  }

  /**
   * /promotion/item
   *
   * @param params
   */
  promotionItem(params: object = {}): Observable<any> {
    return this._api.getCached('/promotion/item', params);
  }

  /**
   * /promotion/list
   *
   * @param params
   */
  promotionList(params: object = {}): Observable<any> {
    return this._api.getCached('/promotion/list', params);
  }

  /**
   * /game/provider/list
   *
   * @param params
   */
  gameProviderList(params: object = {}): Observable<any> {
    return this._api.getCached('/game/provider/list', params);
  }

  /**
   * /tournament/item
   *
   * @param params
   */
  tournamentItem(params: object = {}): Observable<any> {
    return this._api.getCached('/tournament/item', params);
  }

  /**
   * /tournament/list
   *
   * @param params
   */
  tournamentList(params: object = {}): Observable<any> {
    return this._api.getCached('/tournament/list', params);
  }

  /**
   * /page/menu
   *
   * @param params
   */
  pageMenu(params: object = {}): Observable<any> {
    return this._api.getCached('/page/menu', params);
  }

  /**
   * /page/privacy
   *
   * @param params
   */
  pagePrivacy(params: object = {}): Observable<any> {
    return this._api.getCached('/page/privacy', params);
  }

  /**
   * /page/terms
   *
   * @param params
   */
  pageTerms(params: object = {}): Observable<any> {
    return this._api.getCached('/page/terms', params);
  }

  /**
   * /static-content/item
   *
   * @param params
   */
  staticContentItem(params: object = {}): Observable<any> {
    return this._api.getCached('/static-content/item', params);
  }

  /**
   * /static-content/list
   *
   * @param params
   */
  staticContentList(params: object = {}): Observable<any> {
    return this._api.getCached('/static-content/list', params);
  }

  /**
   * /game/list
   *
   * @param params
   */
  gameList(params: object = {}): Observable<any> {
    return this._api.getCached('/game/list', params);
  }

  /**
   * /currency/list/ss
   *
   * @param params
   */
  currencyListSS(params: object = {}): Observable<any> {
    return this._api.getCached('/currency/list/ss', params);
  }

  /**
   * /country/list
   *
   * @param params
   */
  countryList(params: object = {}): Observable<any> {
    return this._api.getCached('/country/list', params);
  }

  /**
   * /service/environment
   *
   * @param params
   */
  serviceEnvironment(params: object = {}): Observable<any> {
    return this._noLangApi.get('/service/environment');
  }

  /**
   * /localization/list
   *
   * @param params
   */
  translateList(params: object = {}): Observable<any> {
    return this._api.getCached('/localization/list', params);
  }

  /**
   * POST: /page/contact-us
   *
   * @param data
   */
  pageContactUs(data: object): Observable<any> {
    return this._api.post('/page/contact-us', data);
  }

}
