import { Injectable } from '@angular/core';
import { ContentUpdaterItem } from './content-updater-item';
import { LanguageService } from '../language/language.service';
import { delay, tap } from 'rxjs/operators';

/**
 * Uses for UpdateOnLangChange decorator
 * Updates value on lang change
 */
@Injectable({
  providedIn: 'root'
})
export class ContentUpdaterService {

  /**
   * Storage for content emitters
   */
  private _storage: Map<string, ContentUpdaterItem> = new Map<string, ContentUpdaterItem>();

  constructor(
    private _lang: LanguageService
  ) {
    this._langChangeHandler();
  }

  /**
   * Add new item to storage
   *
   * @param key
   * @param item
   */
  addItem(key: string, item: ContentUpdaterItem) {
    if (!this._storage.has(key)) {
      this._storage.set(key, item);
    }

    const addedItem = this._storage.get(key);

    if (!addedItem.updated) {
      addedItem.value.next(undefined);
    }

    return addedItem.value.pipe(
      tap(val => {
        if (!addedItem.updated && val !== null) {
          this._updateItemByKey(key);
        }
      })
    );
  }

  /**
   * Update all items that has active subscribers
   */
  updateAll() {
    this._storage.forEach((item: ContentUpdaterItem, key: string) => {
      if (this._hasActiveSubscribers(item.value)) {
        this._updateItemByKey(key);
      }
    });
  }

  /**
   * Update item value in storage
   *
   * @param key
   * @private
   */
  private _updateItemByKey(key: string) {
    const item = this._storage.get(key);

    item.value.next(null);
    item.updateFn().pipe(
      /**
       * Needs for templates re-rendering
       */
      delay(1)
    ).subscribe(response => {
      item.updated = true;
      item.value.next(response);
    });
  }

  /**
   * Update items in storage
   *
   * @private
   */
  private _langChangeHandler() {
    this._lang.langChange$.pipe(
      tap(lang => {
        this.updateAll();
      })
    ).subscribe();
  }

  /**
   * Returns true if provided observable has active subscribers
   *
   * @param observable
   * @private
   */
  private _hasActiveSubscribers(observable): boolean {
    // tslint:disable:no-string-literal
    return (
      observable.observers.length ||
      observable['destination'] &&
      observable['destination']['observers'] &&
      observable['destination']['observers'].length
    );
    // tslint:enable:no-string-literal
  }
}
