import { Component } from '@angular/core';
import {DataPreloaderService} from './shared/services/data-preloader.service';
import { GlobalEventsService } from './shared/services/global-events.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'affiliate';

  constructor(
    public loader: DataPreloaderService,
    private _globalEvent: GlobalEventsService
  ) {
    this._globalEvent.initGlobalEvent();
  }

}
